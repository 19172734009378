<template>
	<div class="mobile-layout-non-header-footer">
		<div class="col-12">
			<slot />
		</div>
	</div>
</template>

<style lang="scss" scoped>
	.mobile-layout-non-header-footer :deep() {
		@import "../../assets/styles/_mobile";
		user-select: none;
		font-family: Pretendard, 돋움, dotum, sans-serif !important;
	}
</style>
